<template>
  <div class="page-wrap">
    <img
      class="page-img"
      :src="`https://cdn.qiumo.net.cn/allsmarketmaterial/test/bg.png`"
    />
    <input
      class="input"
      v-model="phone_number"
      type="tel"
      maxlength="11"
      placeholder="请输入登录的手机号码"
    />
    <img
      class="order-button"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/test/btn.png"
      @click="onSubmitOrder"
    />
    <div class="protocol-wrap" @click="checked = !checked">
      <img
        class="protocol-check"
         :src="`https://cdn.qiumo.net.cn/allsmarketmaterial/test/${
          checked ? 'selected_icon' : 'unselected_icon'
        }.png`"
      />
       <div class="protocol-text">业务资费：每月仅3元，输入手机号后使用支付宝，活动测试链接，无实际权益</div>
    </div>
    <div class="tag">帖芯客服热线:4001189599</div>
  </div>
</template>

<script>
import useReferrerMate from '@/mixin/useReferrerMate.js';
import { alipayNoSignPayTiexin } from "@/api/index.js";
import { Toast } from "vant";

export default {
  mixins: [useReferrerMate],
  data() {
    return {
      phone_number: "",
      checked: false,
    };
  },
  computed: {
    type() {
      return this.$route.query.type || "oneFen";
    },
  },
  methods: {
    onSubmitOrder() {
      if (!this.phone_number) return Toast("请输入手机号码");
      let filterPhone = /^1[3456789]\d{9}$/;
      const pass = filterPhone.test(this.phone_number);
      if (!pass) return Toast("手机号码格式不正确");
      if (!this.checked) return Toast("请阅读并同意服务协议");
      this.$toast({
        type: "loading",
        message: "加载中...",
        duration: 0,
        forbidClick: true,
      });
      const { from: order_channel_from = "" } = this.$route.query;
      alipayNoSignPayTiexin(
        { order_channel_from },
        {
          phone_number: this.phone_number,
          item_name: '三元会员',
        }
      )
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            const container = document.createElement("div");
            container.innerHTML = res.sign_str;
            document.body.appendChild(container);

            const form = container.querySelector('form[name="punchout_form"]');
            if (form) {
              form.submit();
              document.body.removeChild(container);
            } else {
              console.error("未能找到支付表单");
            }
          } else {
            Toast.fail(res.message || "订购失败");
          }
        })
        .catch((err) => {
          Toast.fail("服务器错误");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.page-wrap {
  position: relative;
  background-color: #ffbc02;
  height: 100%;

  .page-img {
    width: 100vw;
  }
  .input {
    width: 660px;
	  height: 98px;
    border: none;
    border-radius: 49px;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 882px;
    transform: translateX(-50%);
    padding: 0 32px;
    font-size: 32px;
    color: #a21008;
    text-align: center;

    &::placeholder {
      color: #a21008;
      font-size: 32px;
      opacity: 0.5;
    }
  }
  .order-button {
    width: 620px;
	  height: 112px;
    border: none;
    position: absolute;
    left: 50%;
    top: 1025px;
    transform: translateX(-50%);
  }
  .protocol-wrap {
    width: 100vw;
    position: absolute;
    left: 50%;
    top: 1208px;
    transform: translateX(-50%);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-size: 22px;
    line-height: 22px;
    font-weight: bold;
    color: #000;
    .protocol-check {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      margin-top: 8px;
    }
    .protocol-text {
      font-size: 24px;
      width: 560px;
      letter-spacing: 0px;
      text-align: center;
      color: #a21008;
      line-height: 36px;
    }
  }
  .tag {
    text-align: center;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 36px;
    letter-spacing: 0px;
    color: #a21008;
    position: absolute;
    left: 0;
    right: 0;
    top: 1322px;
  }

}
</style>
